<template>
  <div class="app">
    <div class="nav text-center">🇺🇦 #trade4ua</div>

    <div class="container hero">
      <div class="row">
        <br />
        <div class="col-md-5">
          <h1 class="heading">Trade for <span class="blue">Ukraine</span></h1>
          <p class="mt-20">
            We call upon financial traders, brokers, liquidity providers,
            trading platform developers and everyone involved in the financial
            trading industry to support our Ukrainian heroes.
          </p>

          <a href="#help"><div class="button black mt-40">Donate</div></a>
          <a href="#partners"
            ><div class="button ghost mt-20">View supporters</div></a
          >
        </div>
        <div class="col-md-5">
          <div class="coat-wrapper text-center mt-40">
            <div class="coat">
              <img src="/coat.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sky text-center">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="count-area">
              <h1>Raised so far:</h1>
              <br />

              <span class="number" v-for="f in flip" v-bind:key="f">{{
                f
              }}</span>
              <span class="number">$</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="break"></div>

    <!-- <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p class="high">
            For years, we have been building affiliate websites and suggesting
            the brokers that provide the lowest minimum deposits, the most
            versatile trading platforms, the widest choice of available assets,
            and the highest leverage. Yet, times have changed. Now we are
            composing a list of the trading industry participants with the
            biggest hearts.
          </p>
        </div>
      </div>
    </div> -->

    <div class="break"></div>

    <div class="container" id="help">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>How can you help?</h1>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="box">
            <h2>Traders</h2>
            <p>
              Open an account with one of the trading partners and all the
              affiliate proceeds will be used to aid Ukraine
            </p>
            <a href="#partners"><div class="button black">View list</div></a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="box">
            <h2>Brokers</h2>
            <p>
              Confirm your donation and we will list your brokerage in the
              honorable trading partners section
            </p>
            <a href="#brokers"><div class="button black">Get in touch</div></a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="box">
            <h2>Everyone else</h2>
            <p>
              Confirm your donation and we will get your company listed amongst
              our honorable mentions
            </p>
            <a href="#everyone"
              ><div class="button black">Donate directly</div></a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="break"></div>

    <div class="container" id="partners">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>Honorable trading partners</h1>
          <br />
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="partner text-center">
            <div class="logo">
              <img src="/logo.jpg" alt="" />
            </div>
            <span class="name">Your company here</span>

            <div class="row">
              <div class="col-md-6">
                <span class="subh">Donated:</span><br />
                <span class="data">$1,000</span>
              </div>
              <div class="col-md-6">
                <span class="subh">When:</span><br />
                <span class="data">Today</span>
              </div>
            </div>

            <a href="#brokers"><div class="button green">Join us</div></a>
          </div>
        </div>
        <div class="col-md-3" v-for="p in partners" v-bind:key="p.name">
          <div class="partner text-center">
            <div class="logo">
              <img :src="'/' + p.name.toLowerCase() + '.png'" alt="" />
            </div>
            <span class="name">{{ p.name }}</span>

            <div class="row">
              <div class="col-md-6">
                <span class="subh">Donated:</span><br />
                <span class="data">{{ curr(p.donated) }}</span>
              </div>
              <div class="col-md-6">
                <span class="subh">When:</span><br />
                <span class="data">{{ pretty(p.date) }}</span>
              </div>
            </div>

            <a :href="p.link" target="_blank">
              <div class="button visit">{{ p.btn }}</div>
            </a>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p>
              Most of the above listed brokers pay TradeForUkraine.com a certain
              compensation for client trading activity. This is done at no
              additional cost to a trader, and all (100%) of the proceeds
              generated from TradeForUkraine.com will be donated to humanitarian
              aid.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container" id="mentions">
      <div class="row">
        <div class="col-md-12 text-center mt-40">
          <h1>Honorable mentions</h1>
          <br />
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <span class="mention" v-for="m in mentions" v-bind:key="m.link">
              <a :href="m.link">{{ m.name }}</a>
            </span>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <br />
            <p>The kind people above have donated for a good cause</p>
          </div>
        </div>
      </div>
    </div>

    <div class="break"></div>

    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1>FAQ</h1>
          <br />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h3 id="brokers">How do I participate as a Partner?</h3>
          <p>
            Make a contribution to
            <a href="https://www.comebackalive.in.ua/donate" target="_blank"
              >comebackaline.in.ua</a
            >

            other
            <a
              href="https://mkip.gov.ua/en/content/volonterski--organizacii.html"
              target="_blank"
              >approved volunteer organizations</a
            >, or any other organisation of your choice, mail us at
            <a href="mailto:hello@tradeforukraine.com" target="_blank"
              >hello@tradeforukraine.com</a
            >
            with the transaction confirmation and we will get you on the list of
            our honourable partners. We will include your logo, brandname,
            donation size and a link (most likely affiliate) to your account
            opening page. If you decide to make an additional donation, simply
            let us know so we can update the entry.
          </p>

          <h3>What if I want to donate anonymously?</h3>
          <p>
            This is certainly a possibility. Reach out to us at
            <a href="mailto:hello@tradeforukraine.com" target="_blank"
              >hello@tradeforukraine.com</a
            >
            and we will make your funds serve a good cause.
          </p>
          <h3>What if I already donated?</h3>
          <p>
            We will include you anyway! Please provide proof of payment and your
            details to:
            <a href="mailto:hello@tradeforukraine.com" target="_blank"
              >hello@tradeforukraine.com</a
            >.
          </p>
          <h3>
            Can I donate directly to <strong>tradeforukraine.com</strong>?
          </h3>
          <p>
            Yes, you can send your donation directly to us, and it will be
            distributed to relevant causes via
            <a
              href="https://www.teatmik.ee/en/personlegal/14549907-SonderSpot"
              target="_blank"
              >SonderSpot OÜ</a
            >, where
            <a
              href="https://www.linkedin.com/in/mykytabarabanov/"
              target="_blank"
              >Mykyta Barabanov</a
            >
            is the sole owner. Please get in touch:
            <a href="mailto:hello@tradeforukraine.com" target="_blank"
              >hello@tradeforukraine.com</a
            >.
          </p>
          <h3 id="everyone">
            I am not a trader or a broker, how do I participate?
          </h3>
          <p>
            Trading is not just about the traders and brokers. We welcome
            everyone from the industry to participate. Follow the same steps as
            for the brokers, and we will get your company listed with a link to
            your website.
          </p>
          <h3>Why should I participate?</h3>
          <p>
            We hope for a sincere contribution. Yet you are free to have your
            own reasoning: it may be a desire to get more clients, SEO value of
            a backlink or anything else. We don't care about your motivation as
            long as you help our heroes.
          </p>
          <h3>How long will I get to keep the listing?</h3>
          <p>
            The listing is indefinite, regardless the size of your contribution.
            We will commit our best efforts to maintaining the website and the
            honour of supporters for years to come.
          </p>
          <h3>I want my company to be listed as top1, what do I do?</h3>
          <p>
            Simply donate more than any other company, and you will be listed in
            the 1st position. We do not accept any sponsored deals other than a
            simple donation.
          </p>
          <h3>
            What will happen to affiliate accounts revenue after the war is
            over?
          </h3>
          <p>
            Revenue share deals (aka IB commissions) are usually paid for a
            lifetime. While we hope for the war to end as soon as possible, we
            will be sending all the affiliate revenue to humanitarian aid.
            Afterwards, we will donate the revenue to help rebuild Ukraine.
          </p>
          <h3>How are we profiting from TradeForUkraine.com?</h3>
          <p>
            We don't. All the costs (development, hosting, marketing etc) are
            fully covered by us. We don't take a single cent from affiliate
            revenue to cover any of our costs, and we don't take any service fee
            to maintain the website. Our only gain here is a better future for
            Ukraine, for our friends and families that are currently hiding in
            shelters.
          </p>
          <h3>Who is behind the project?</h3>
          <p>
            We don't want to take any credit for the project. Yet we feel that
            it is important for you to verify the people behind it. The project
            was founded by
            <a href="https://elitecurrensea.com/" target="_blank"
              >Elite CurrenSea</a
            >,
            <a href="https://financemakers.com/" target="_blank"
              >Finance Makers</a
            >,
            <a href="https://www.yourbourse.com/" target="_blank"
              >Your Bourse</a
            >
            and their friends.
          </p>
          <h3>Is TradeForUkraine.com only for the companies?</h3>
          <p>
            No. As a trader, you can simply sign up with one of the suggested
            brokers, and it will help us make future donations that will be
            derived from the affiliate commissions. Also, if you are an employee
            of a certain company, your donation can certainly be accepted on
            behalf of the company.
          </p>
          <!-- <h3>What if I am pro-Russia?</h3>
          <p>
            We feel no hate. Being pro-Russia is a temporary condition that is
            most likely caused by misinformation. Just
            <a href="https://www.youtube.com/watch?v=Dnh3q2zp3qA">click here</a>
            to watch a video and get a sample of what you are supporting.
          </p> -->
        </div>
      </div>
    </div>

    <br />
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      sort: "One",
      partners: [
        {
          name: "XTB",
          donated: "230000",
          date: "1646307046271",
          link: "https://www.xtb.com/",
          btn: "Open account",
        },
        {
          name: "Admirals",
          donated: "100000",
          date: "1646307046271",
          link: "https://admiralmarkets.com/start-trading/admiral-invest-stocks-and-etfs?raf=52274001",
          btn: "Open account",
        },
        {
          name: "GetID",
          donated: "50000",
          date: "1646307046271",
          link: "https://getid.com/",
          btn: "Visit website",
        },
        {
          name: "FXDD",
          donated: "5000",
          date: "1647248764862",
          link: "https://www.fxdd.com/mt/en",
          btn: "Open account",
        },
        {
          name: "Match Trade",
          donated: "23000",
          date: "1646307046271",
          link: "https://match-trade.com/",
          btn: "Visit website",
        },
        {
          name: "Axiory",
          donated: "2216",
          date: "1646134620000",
          link: "https://www.axiory.com/",
          btn: "Open account",
        },
        {
          name: "Milton Prime",
          donated: "1000",
          date: "1646222929282",
          link: "https://portal.miltonprime.com/links/go/1568",
          btn: "Open account",
        },
      ],
      // mentions here
      mentions: [
        {
          name: "Elite CurrentSea",
          link: "https://elitecurrensea.com/",
        },
        {
          name: "Finance Makers",
          link: "https://financemakers.com/",
        },
      ],
      filterDate: false,
    };
  },
  computed: {
    total: function () {
      const sum = this.partners.reduce(function (a, b) {
        return { donated: parseInt(a.donated) + parseInt(b.donated) };
      });
      return sum.donated;
    },
    flip: function () {
      return this.total.toString().split("");
    },
  },
  methods: {
    pretty: function (ms) {
      const date = new Date(parseInt(ms));
      // const d = date.toLocaleString();
      var datestring =
        date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
      //console.log(d);
      return datestring;
    },
    curr: function (num) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });

      return formatter.format(num); /* $2,500.00 */
    },
  },
};
</script>

<style lang="scss">
#app {
}
</style>